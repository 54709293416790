import { adminRoute, routes } from "../../constants/routes";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { setRoute, submitLoginCreds, checkLoginStatus, getBrandDashboards, appLoaded, getDrilldownReports, logout } from "../../actions/app";
import { Route } from "../../models/interfaces/route";
import { setDrilldownReport } from "../../actions/drilldown";

export interface State {
  routes: Route[];
  route: string;
  isLoggedIn: boolean;
  logInAttempts: number;
  isLoading: boolean;
  user: any; // TODO: Create an actual user type
}

export const INITIAL_STATE: State = {
  routes: [],
  route: "/dashboards",
  isLoggedIn: false,
  logInAttempts: 0,
  isLoading: true,
  user: null,
};

const App = reducerWithInitialState(INITIAL_STATE);

// Reducers
App.case(
  setRoute,
  (state: State, payload): State => ({
    ...state,
    route: payload.route,
  })
);
App.case(
  appLoaded,
  (state: State, payload): State => ({
    ...state,
    routes: routes,
  })
)
App.case(
  submitLoginCreds.async.done,
  (state: State, { result }): State => ({
    ...state,
    isLoggedIn: result.loggedIn,
    user: result.user,
    routes: result.user.is_admin || true ? [...routes, adminRoute] : routes, // TODO: remove || true once done with testing
    logInAttempts: (result.loggedIn === true ? 0 : state.logInAttempts + 1)
  })
);
App.case(
  submitLoginCreds.async.failed,
  (state: State, { error }): State => ({
    ...state,
    isLoggedIn: false,
    logInAttempts: state.logInAttempts + 1,
  })
);
App.case(
  checkLoginStatus.async.started,
  (state: State): State => ({
    ...state,
    isLoading: true,
  })
);
App.case(
  checkLoginStatus.async.done,
  (state: State, { result }): State => ({
    ...state,
    isLoggedIn: result.loggedIn,
    user: result.user,
    routes: result.user.is_admin || true ? [...routes, adminRoute] : routes, // TODO: remove || true once done with testing
    isLoading: false,
  })
);
App.case(
  checkLoginStatus.async.failed,
  (state: State, { error }): State => ({
    ...state,
    isLoggedIn: false,
    isLoading: false,
  })
);
App.case(
  getBrandDashboards.async.started,
  (state: State): State => ({
    ...state,
  })
);
App.case(
  getBrandDashboards.async.done,
  (state: State, { result }): State => {
    const newRoutes = state.routes;
    if (result.length > 0) {
      newRoutes[0].subroutes = result;
      newRoutes[0].componentProps = result[0]?.componentProps ?? { srcUrl: '' };
    }
    return ({
      ...state,
      routes: [...newRoutes],
    })
  }
);
App.case(
  getBrandDashboards.async.failed,
  (state: State, { error }): State => ({
    ...state,
  })
);
App.case(
  getDrilldownReports.async.started,
  (state: State): State => ({
    ...state,
  })
);
App.case(
  getDrilldownReports.async.done,
  (state: State, { result }): State => {
    const newRoutes = state.routes;
    newRoutes[1].subroutes = result;
    return ({
      ...state,
      routes: [...newRoutes],
    })
  }
);
App.case(
  getDrilldownReports.async.failed,
  (state: State, { error }): State => ({
    ...state,
  })
);
App.case(
  setDrilldownReport.async.started,
  (state: State): State => ({
    ...state,
  })
);
App.case(
  setDrilldownReport.async.done,
  (state: State, { result }): State => {
    const newRoutes = state.routes;
    newRoutes[1].subroutes = result;
    return ({
      ...state,
      routes: [...newRoutes],
    })
  }
);
App.case(
  setDrilldownReport.async.failed,
  (state: State, { error }): State => ({
    ...state,
  })
);
App.case(
  logout.async.done,
  (state: State): State => ({
    ...state,
    isLoggedIn: false,
  })
);

// Selectors

export const selectRoute = (state: State) => state.route;
export const selectIsLoggedIn = (state: State) => state.isLoggedIn;
export const selectIsAppLoading = (state: State) => state.isLoading;
export const selectLogInAttempts = (state: State) => state.logInAttempts;
export const selectRoutes = (state: State) => state.routes;
export const selectIsAdmin = (state: State) => state.user?.is_admin === true ?? false;
export const selectCurrUser = (state: State) => state.user;

export default App;
