import React, { FunctionComponent, useState } from "react";
import DatePickerElem from "react-datepicker";
import './style.css';
import "react-datepicker/dist/react-datepicker.css";
interface Props {
    value: Date;
    onChange(newDate: Date): void;
}

export const DatePicker: FunctionComponent<Props> = (props: Props) => {
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const handleDateChange = (newDate: Date | null) => {
        if (newDate !== null) {
            props.onChange(newDate)
        }
    }
    return (
        <div>
            <DatePickerElem className={'dateInput'} selected={props.value} onChange={date => handleDateChange(date)} />
        </div>
    );
};


