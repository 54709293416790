import { IFrame } from "../../components/main-content/iframe";

export const fetchLogin = async (username: string, password: string, rememberMe: boolean) => {
    const url = process.env.REACT_APP_WEB2PY_BASE_URL + '/xw/api/doAuth';
    username = encodeURIComponent(username);
    return await fetch(url, {
        method: 'POST',
        body: `username=${username}&password=${password}&remember=${rememberMe ? 'true' : 'false'}`,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    }).then(res => res.json()).then(res => {
        let response = res;
        console.log('response', response);
        if (typeof response === 'string') {
            response = JSON.parse(res);
        }
        if (response.urlRedirect != undefined) {
            const url = response.urlRedirect + '#/';
            window.location.href = url;
        }

        const { loggedIn, ...rest } = response;
        return { loggedIn, user: rest };
    });
};

export const fetchResetPassword = async (username: string) => {
    username = encodeURIComponent(username);
    const url = process.env.REACT_APP_WEB2PY_BASE_URL + '/xw/default/user/request_reset_password?email=' + username;
    return await fetch(url, {
        method: 'GET',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    }).then(() => { return true });
};

export const fetchSetNewPassword = async (key: string, password: string) => {
    const url = process.env.REACT_APP_WEB2PY_BASE_URL + `/xw/api/reset_password?key=${key}&password=${password}`;
    return await fetch(url, {
        // method: 'POST',
        // body: `password=${password}&password2=${password}&key=${key}`,
        // headers: {
        //     "Content-Type": "application/x-www-form-urlencoded",
        // },
    }).then(() => {
        window.location.href = '/';
    })
};

export const fetchAcceptTos = async () => {
    const url = process.env.REACT_APP_WEB2PY_BASE_URL + '/xw/api/accept_tos';
    return await fetch(url, {
        method: 'GET',
    }).then(res => res.json()).then(res => {
        let response = res;
        console.log('response', response);
        if (typeof response === 'string') {
            response = JSON.parse(res);
        }
        if (response.urlRedirect != undefined) {
            const url = response.urlRedirect + '#/';
            window.location.href = url;
        }

        const { loggedIn, ...rest } = response;
        return { loggedIn, user: rest };
    });
}

export const fetchLogout = async () => {
    const url = process.env.REACT_APP_WEB2PY_BASE_URL + '/xw/default/user/logout';
    return await fetch(url, {
        method: 'GET',
    }).then(() => true);
};

export const fetchLoginStatus = async () => {
    const url = process.env.REACT_APP_WEB2PY_BASE_URL + "/xw/api/userState";
    return await fetch(url).then(res => res.json()).then(res => {
        let response = res;
        console.log('response', response);
        if (typeof response === 'string') {
            response = JSON.parse(res);
        }
        if (response.urlRedirect != undefined) {
            const url = response.urlRedirect + '#/';
            window.location.href = url;
        }

        const { loggedIn, ...rest } = response;
        return { loggedIn, user: rest };
    });
};

export const fetchBrandDashboards = async () => {
    const url = process.env.REACT_APP_WEB2PY_BASE_URL + "/xw/dash/api/saved-dashboards";
    return await fetch(url).then(res => res.json()).then(res => {
        return res.map((dashboard: { [key: string]: any }) => {
            return {
                path: `/dashboards/${dashboard.id}`,
                title: dashboard.name,
                component: IFrame,
                componentProps: {
                    srcUrl: dashboard.url,
                }
            }
        })
    });
}
