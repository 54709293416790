import React, { FunctionComponent, useEffect, useState, useRef, RefObject, RefAttributes } from "react";
import styled from "styled-components";
import Popup from "reactjs-popup";
import { SearchField } from "../search-field/search-field";
import { BookmarkBorderRounded } from '@material-ui/icons'; // addchart
import { Button, SelectedButton } from '../button';
import { IconButton, SelectedIconButton } from '../icon-button';
import { selectDrilldownReports } from "../../../reducers";
import { useSelector } from "react-redux";
import InputViolationText from "../../input-violation-text/input-violation-text"
import { PopupActions, PopupProps } from "reactjs-popup/dist/types";

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 6px;
`;

interface SaveReportProps {
    onSaveReportClick(reportTitle: string): void
}

const SaveReportPopdown: FunctionComponent<SaveReportProps> = (props: SaveReportProps) => {
    const [firstVisit, setFirstVisit] = useState(true);
    const [reportTitle, setReportTitle] = useState<string>("");
    const drilldownReports = useSelector(selectDrilldownReports);

    const popupRef = useRef<PopupActions>(null);
    const closeTooltip = () => {
        if (popupRef !== null && popupRef !== undefined) {
            if (popupRef.current !== undefined && popupRef.current !== null) {
                popupRef.current.close();
            }
        }
    }

    const saveReportAndCloseModal = () => {
        closeTooltip()
        console.log('saveandclose');
        props.onSaveReportClick(reportTitle);
        console.log('after onsaveclick')
        setReportTitle('');
    }

    const setReportTitleWrapper = (newText: string) => {
        if (firstVisit && !newText) {
            setFirstVisit(false)
        }

        setReportTitle(newText)
    }

    const duplicateName = drilldownReports ? drilldownReports.some(report => (report.title !== null && reportTitle.toLowerCase() === report.title.toLowerCase())) : false;
    const emptyInput = !reportTitle
    const disableButton = duplicateName || emptyInput

    return (
        <Popup
            ref={popupRef}
            trigger={<IconButton><BookmarkBorderRounded /></ IconButton>}
            position="bottom center"
        >
            <>
                <SearchField
                    label="Report Name"
                    value={reportTitle}
                    onChange={setReportTitleWrapper}
                />
                {
                    duplicateName &&
                    <InputViolationText>There is already a report with this name</InputViolationText>
                }
                {
                    emptyInput && !firstVisit &&
                    <InputViolationText>Please enter a title for the report</InputViolationText>
                }
                <ButtonRow>
                    <Button disabled={disableButton} onClick={saveReportAndCloseModal} style={{ padding: '6px 6px 0px 6px;' }}>Save</Button>
                </ButtonRow>
            </>
        </Popup >
    );
}

export default SaveReportPopdown;