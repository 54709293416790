import { User } from "../../models/interfaces/user";


export const fetchUsers = async () => {
    const url = process.env.REACT_APP_WEB2PY_BASE_URL + '/xw/api/users';
    return await fetch(url, {
        method: 'GET',
    }).then(res => res.json())
        .then((data: { [key in string]: any }[]) => {
            console.log('GOT THE USERS!!!', data)
            const transformedUsers: User[] = data.map(user => {
                return {
                    lastName: user.last_name,
                    firstName: user.first_name,
                    email: user.email,
                    id: user.id,
                    isAdmin: user.is_admin ?? false,
                }
            })
            return transformedUsers;
        });
};

export const createNewUser = async (user: User) => {
    const url = process.env.REACT_APP_WEB2PY_BASE_URL + '/xw/api/users';
    // console.log('services user=', user)
    return await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email,
            is_admin: user.isAdmin,
        })
    }).then(res => res.json())
        .then((data: { [key in string]: any }[]) => {
            // console.log('MADE A USER!!!', data)
            const transformedUsers: User[] = data.map(user => {
                return {
                    lastName: user.last_name,
                    firstName: user.first_name,
                    email: user.email,
                    id: user.id,
                    isAdmin: user.is_admin ?? false,
                }
            })
            return transformedUsers;
        });
};

export const fetchEditUser = async (user: User) => {
    const url = process.env.REACT_APP_WEB2PY_BASE_URL + '/xw/api/users/' + user.id;
    return await fetch(url, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            id: user.id,
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email,
            is_admin: user.isAdmin,
        })
    }).then(res => res.json())
        .then((data: { [key in string]: any }[]) => {
            const transformedUsers: User[] = data.map(user => {
                return {
                    lastName: user.last_name,
                    firstName: user.first_name,
                    email: user.email,
                    id: user.id,
                    isAdmin: user.is_admin ?? false,
                }
            })
            return transformedUsers;
        });
};

export const fetchDeleteUser = async (userId: number) => {
    const url = process.env.REACT_APP_WEB2PY_BASE_URL + `/xw/api/users/${userId}`;
    // console.log('services user=', user)
    return await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'content-type': 'application/json',
        },
    }).then(res => res.json())
        .then((data: { [key in string]: any }[]) => {
            // console.log('MADE A USER!!!', data)
            const transformedUsers: User[] = data.map(user => {
                return {
                    lastName: user.last_name,
                    firstName: user.first_name,
                    email: user.email,
                    id: user.id,
                    isAdmin: user.is_admin ?? false,
                }
            })
            return transformedUsers;
        });
};
