import React, { FunctionComponent } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import './style.css';

const MainDiv = styled.div`
  border: none;
  width: 100%;
  position: relative;
`;

const Button = styled.div`
  padding: 16px;
  border: 1px solid black;
`;

interface Props {
  value: string;
  label: string;
  onChange(newSearchTerm: string): void;
}

export const SearchField: FunctionComponent<Props> = (props: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.currentTarget.value);
  };
  return (
    <MainDiv>
      <TextField
        style={{ width: "100%" }}
        label={props.label}
        variant="outlined"
        value={props.value}
        onChange={handleChange}
      />
    </MainDiv>
  );
};
