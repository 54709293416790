export const categories = [
  {
    label: "Campaign Page Views",
    value: "ga:topPVs",
  },
  {
    label: "Orders",
    value: "ga:uniquePurchases",
  },
  {
    label: "Product Page Views",
    value: "ga:uniquePageviews",
  },
  {
    label: "Revenue",
    value: "ga:itemRevenue",
  },
  {
    label: "Units Sold",
    value: "ga:itemQuantity",
  },
];

export const dimensions = [
  {
    label: "Campaign",
    value: "ga:campaign",
  },
  {
    label: "Category",
    value: "category",
  },
  {
    label: "Family",
    value: "line",
  },
  {
    label: "Color",
    value: "color",
  },
  {
    label: "Gender",
    value: "gender",
  },
  {
    label: "Market",
    value: "market",
  },
  {
    label: "Placement",
    value: "ga:keyword",
  },
  {
    label: "Product",
    value: "product",
  },
  {
    label: "Retailer",
    value: "retailer",
  },
  {
    label: "Segment",
    value: "subsegment",
  },
  {
    label: "Size",
    value: "size",
  },
  {
    label: "Source",
    value: "ga:source",
  },
  {
    label: "Traffic Medium",
    value: "ga:medium",
  },
  {
    label: 'None',
    value: 'none',
  }
];
