/*
 * Combine all available reducers to a single root reducer.
 */
import { combineReducers } from "redux";

/* Import here each of the reducers */
import app, * as fromApp from "./app";
import drilldown, * as fromDrilldown from "./drilldown";
import datasources, * as fromDatasources from './datasources';
import admin, * as fromAdmin from "./admin";

export interface RootState {
  app: fromApp.State;
  drilldown: fromDrilldown.State;
  datasources: fromDatasources.State;
  admin: fromAdmin.State;
}

const reducers = {
  app,
  drilldown,
  datasources,
  admin,
};
const combined = combineReducers<RootState>(reducers);

export default combined;

// Selector

/* Export selectors here. e.g:
 *   export const getAppValue = (state: RootState) => fromApp.getValue(state.app);
 */
export const selectRoute = (state: RootState) => fromApp.selectRoute(state.app);
export const selectIsLoggedIn = (state: RootState) => fromApp.selectIsLoggedIn(state.app);
export const selectIsAppLoading = (state: RootState) => fromApp.selectIsAppLoading(state.app);
export const selectLogInAttempts = (state: RootState) => fromApp.selectLogInAttempts(state.app);
export const selectIsAdmin = (state: RootState) => fromApp.selectIsAdmin(state.app);

export const selectProducts = (state: RootState) =>
  fromDrilldown.selectProducts(state.drilldown);
export const selectRetailers = (state: RootState) =>
  fromDrilldown.selectRetailers(state.drilldown);
export const selectDrilldownUrl = (state: RootState) =>
  fromDrilldown.selectDrilldownUrl(state.drilldown);
export const selectChartType = (state: RootState) =>
  fromDrilldown.selectChartType(state.drilldown);
export const selectStartDate = (state: RootState) => fromDrilldown.selectStartDate(state.drilldown);
export const selectEndDate = (state: RootState) => fromDrilldown.selectEndDate(state.drilldown);
export const selectCampaigns = (state: RootState) => fromDrilldown.selectCampaigns(state.drilldown);
export const selectCampaignsLoading = (state: RootState) => fromDrilldown.selectCampaignsLoading(state.drilldown);
export const selectDatasourceRequests = (state: RootState) =>
  fromDatasources.selectDatasourceRequests(state.datasources);
export const selectDatasourcesProviding = (state: RootState) => {
  console.log('state.datasources', state.datasources)
  return fromDatasources.selectDatasourcesProviding(state.datasources);
}
export const selectDatasourcesReceiving = (state: RootState) =>
  fromDatasources.selectDatasourcesReceiving(state.datasources);
export const selectRoutes = (state: RootState) => fromApp.selectRoutes(state.app);
export const selectDrilldownReports = (state: RootState) => { console.log('in reducers index', state); return fromDrilldown.selectDrilldownReports(state.drilldown) };

export const selectUsers = (state: RootState) => fromAdmin.selectUsers(state.admin);
export const selectUsersLoading = (state: RootState) => fromAdmin.selectUsersLoading(state.admin);
export const selectSelectedUser = (state: RootState) => fromAdmin.selectSelectedUser(state.admin);
