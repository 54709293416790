import styled from "styled-components";
import { Button, SelectedButton } from './button'

const IconButton = styled(Button)`
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;

`;

const SelectedIconButton = styled(SelectedButton)`
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
`;


export {
  IconButton,
  SelectedIconButton
};