import React, { FunctionComponent, useEffect } from "react";
import styled from "styled-components";
import Checkbox from "@material-ui/core/Checkbox";

const MainDiv = styled.div`
  width: 100%;
  border: none;
  padding: 0.18rem;
  display: flex;
`;

const Label = styled.div`
  padding-left: 16px;
  font-size: 13px;
  font-weight: 400;
  color: #686868;
`;

const Input = styled.input`
  color: green;
`;

interface Props {
  label: string;
  isChecked: boolean;
  onCheckToggle(): void;
}

export const CheckboxItem: FunctionComponent<Props> = (props: Props) => {

  return (
    <MainDiv>
      <Checkbox
        checked={props.isChecked}
        onClick={props.onCheckToggle}
        size='small'
        style={{ color: '#66d1d1', padding: 0, marginTop: -4 }}
      />
      <Label>{props.label}</Label>
    </MainDiv>
  );
};
