import React, { FunctionComponent } from "react";

interface Props {
    color: string;
    icon: string;
}

export const Icons: FunctionComponent<Props> = (props: Props) => {
    if (props.icon === 'column') {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill={props.color}><path d="M0 0h24v24H0z" fill="none" /><path d="M5 9.2h3V19H5zM10.6 5h2.8v14h-2.8zm5.6 8H19v6h-2.8z" /></svg>
        );
    }
    if (props.icon === 'donut') {
        return <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill={props.color}><path d="M0 0h24v24H0V0z" fill="none" /><path d="M11 2v20c-5.07-.5-9-4.79-9-10s3.93-9.5 9-10zm2.03 0v8.99H22c-.47-4.74-4.24-8.52-8.97-8.99zm0 11.01V22c4.74-.47 8.5-4.25 8.97-8.99h-8.97z" /></svg>

    }
    if (props.icon === 'funnel') {
        return <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill={props.color}><g><path d="M0,0h24 M24,24H0" fill="none" /><path d="M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6c0,0,3.72-4.8,5.74-7.39 C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z" /><path d="M0,0h24v24H0V0z" fill="none" /></g></svg>

    }
    return <span>No Icon Found</span>
};

