export interface User {
    id: number
    firstName: string;
    lastName: string;
    email: string;
    isAdmin: boolean;
}

export const emptyUser: User = {
    id: -1,
    firstName: '',
    lastName: '',
    email: '',
    isAdmin: false,
}
