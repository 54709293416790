import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Checkbox, CircularProgress, Modal } from "@material-ui/core";
import { selectUsers, selectUsersLoading, selectSelectedUser } from "../../reducers";
import { emptyUser, User } from "../../models/interfaces/user";
import { getUsers, createUser, editUser, setSelectedUser, deleteUser } from "../../actions/admin";
import { submitResetPassword } from "../../actions/app";

const Button = styled.button`
  margin-left: 8px;
  color: white;
  background-color: #727cf5;
  border-color: #727cf5;
  border: 1px solid;
  border-radius: 4px;
  padding: 4px 12px;
  min-width: 120px;
  cursor: pointer;
  height: 32px;
`;

const DeleteButton = styled.button`
  margin-left: 8px;
  color: white;
  background-color: #b80101;
  border-color: #b80101;
  border: 1px solid;
  border-radius: 4px;
  padding: 4px 12px;
  min-width: 120px;
  cursor: pointer;
  height: 32px;
  &:disabled {
    opacity: 0.3;
  }
`;

const SearchBar = styled.input`
  border-color: #727cf5;
  color: #727cf5;
  max-width: 380px;
  width: 100%;
  margin-left: 8px;
  padding-left: 8px;
  height: 26px;
`;

const DeleteInput = styled.input`
font-weight: 600;
    border-color: #b80101;
    font-size: 16px;
    color: black;
    text-transform: uppercase;
    max-width: 160px;
    width: 100%;
    margin-left: 8px;
    padding-left: 8px;
    height: 32px;
`;

const StyledSelect = styled(Select)`

`;

const UserWrapper = styled.div`
  padding: 8px;
  &:nth-child(odd){background-color: #dfe1e4}
  &:nth-child(even){background-color: #727cf5}
  cursor: pointer;
  border-radius: 4px;
  margin: 8px;
  max-width: 380px;
`;
const InputLine = styled.div`
  display: grid;
  grid-template-columns: 120px auto;
  height: 32px;
  align-items: center;
`;

const Input = styled.input`
  border-color: #727cf5;
  color: black;
`;

const ModalWrapper = styled.div`
  background-color: white;
  padding: 16px;
  left: 50%;
  width: calc(100% - 92px);
  transform: translate(-50%, 48px);
  position: absolute;
  min-width: 320px;
  max-width: 500px;
  &:focus {
    outline: none;
    cursor: default;
  }
`;

interface LabelProps {
  active?: boolean,
  clickable: boolean
};

const Label = styled.div<LabelProps>`
  cursor: ${props => props.clickable ? 'pointer' : 'defualt'};
  text-transform: uppercase;
  color: #454545;
  width: 110px;
  margin-right: 20px;

  border-bottom: ${(props: LabelProps) => props.active ? '4px solid #727cf5' : 'none'};
`;

const FullPage = styled.div`
  margin: 48px;
  height: calc(100% - 224px);
`;

interface Props {

}

export const AdminPage: FunctionComponent<Props> = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // fetch the users
    dispatch(getUsers())
  }, []);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('Add New User');
  const [deleteConfirmation, setDeleteConfirmation] = useState<string>('');
  const [editingUser, setEditingUser] = useState<User>(emptyUser);

  const users = useSelector(selectUsers);
  // console.log('users selected are', users)
  const usersLoading = useSelector(selectUsersLoading);
  const selectedUser = useSelector(selectSelectedUser);

  useEffect(() => {
    setEditingUser(selectedUser)
  }, [selectedUser])

  const renderSpinner = () => usersLoading ? (
    <div style={{ width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(4px)', position: 'absolute', top: 0, left: 0, zIndex: 5000, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress size={80} style={{ color: 'rgb(114,124,245)' }} />
    </div>
  ) : null;

  // add edit modal
  const matchingUser = (user: User, term: string) => {
    const matchingEmail = user?.email?.includes(term);
    const matchingFullName = `${user.firstName} ${user.lastName}`.includes(term) || `${user.lastName} ${user.firstName}`.includes(term);

    return matchingEmail || matchingFullName;
  }

  const handleDeleteClick = () => {
    setDeleteConfirmation('');
    setDeleteModalOpen(true);
  }

  const handleDeleteUser = () => {
    dispatch(deleteUser({ userId: selectedUser.id }));
    setDeleteModalOpen(false);
    setModalOpen(false);
  }

  const displayUsers = users.filter((user: User) => matchingUser(user, searchTerm))

  const renderUsers = () => {
    return displayUsers.map(user => (
      <UserWrapper key={user.id} onClick={() => { handleOpenModal(user) }}>
        <div style={{ fontSize: '18px', fontWeight: 600 }}>{user.firstName} {user.lastName}</div>
        <div>{user.email}</div>
      </UserWrapper>)
    )
  }

  const renderDeleteModal = () => {
    if (deleteModalOpen) {
      return (
        <Modal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
        >
          <ModalWrapper>
            <h3>Warning!</h3>
            <div style={{ padding: '4px' }}>This action cannot be undone.</div>
            <div style={{ padding: '4px' }}>
              Please confirm you wish to delete this user by typing <b>DELETE</b> in the box below.
            </div>
            <div style={{ padding: '16px 0' }}>
              <DeleteInput onChange={(e) => { setDeleteConfirmation(e.currentTarget.value) }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <DeleteButton
                onClick={() => handleDeleteUser()}
                disabled={deleteConfirmation.toUpperCase().trim() !== 'DELETE'}
              >
                CONFIRM DELETE
              </DeleteButton>
              <Button onClick={() => { setDeleteModalOpen(false) }}>CANCEL</Button>
            </div>
          </ModalWrapper>
        </Modal>
      )
    }
  }

  const renderEditModal = () => (
    <Modal
      style={{ outline: 'none' }}
      open={modalOpen}
      onClose={() => setModalOpen(false)}
    >
      <ModalWrapper>
        <h3>{title}</h3>
        <InputLine>
          <div>First name: </div><Input value={editingUser.firstName} onChange={(event) => setEditingUser({ ...editingUser, firstName: event.currentTarget.value })} />
        </InputLine>
        <InputLine>
          <div>Last Name: </div><Input value={editingUser.lastName} onChange={(event) => setEditingUser({ ...editingUser, lastName: event.currentTarget.value })} />
        </InputLine>
        <InputLine>
          <div>Email: </div><Input value={editingUser.email} onChange={(event) => setEditingUser({ ...editingUser, email: event.currentTarget.value })} />
        </InputLine>
        <InputLine>
          <div>Admin: </div><Checkbox style={{ color: '#0c1427', width: '24px', marginLeft: '-13px', justifySelf: 'start' }} size="small" checked={editingUser.isAdmin} onChange={() => setEditingUser({ ...editingUser, isAdmin: !editingUser.isAdmin })} />
        </InputLine>
        <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-end' }}>
          {
            title === 'Add New User'
              ? null
              : <DeleteButton onClick={() => handleDeleteClick()}>Delete</DeleteButton>

          }
          {
            title === 'Add New User'
              ? null
              : <Button onClick={() => handleResendInviteEmail()}>Resend Invite Email</Button>
          }
          {
            title === 'Add New User'
              ? <Button onClick={() => handleCreateUser()}>Add User</Button>
              : <Button onClick={() => handleEditUser()}>Save Edits</Button>
          }
        </div>
      </ModalWrapper>
    </Modal>
  )
  const handleOpenModal = (user: User | undefined) => {
    if (user === undefined) {
      setTitle('Add New User');
      dispatch(setSelectedUser({ user: emptyUser }))
    } else {
      setTitle('Edit User');
      dispatch(setSelectedUser({ user }));
    }
    setModalOpen(true);
  }

  const handleEditUser = () => {
    dispatch(editUser({ user: editingUser }));
    setModalOpen(false);
  }
  const handleCreateUser = () => {
    dispatch(createUser({ user: editingUser }));
    setModalOpen(false);
  }

  const handleResendInviteEmail = () => {
    dispatch(submitResetPassword({ username: editingUser.email }));
  }

  return (
    <FullPage>
      <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBlockEnd: '0px' }}>
        Admin
      </h1>
      <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '16px', paddingTop: '8px' }}>
        <SearchBar width={240} placeholder="Search users" value={searchTerm} onChange={(event) => setSearchTerm(event.currentTarget.value)} />
        <Button onClick={() => handleOpenModal(undefined)}>Add User</Button>
      </div>
      <div style={{ height: '100%', overflow: 'auto' }}>
        <div>Users:</div>
        {renderUsers()}
      </div>
      {renderSpinner()}
      {renderEditModal()}
      {renderDeleteModal()}
    </FullPage>
  );

}