import { DatasourceRequest } from '../../models/interfaces/datasource-request';

export const fetchDatasourceRequests = async (): Promise<DatasourceRequest[]> => {
    console.log('fetch datasource requests...')
    return Promise.resolve([{
        name: 'Daniel J. Kochobe',
        description: 'Arc\'Teryx requested Adobe access for their product data',
        picUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/7/72/ARC%27TERYX_logo.svg/1280px-ARC%27TERYX_logo.svg.png',
        timestamp: new Date('2020-08-24'),
        domain: 'google.com',
        datasourceType: 'GA360',
    },
    {
        name: 'Someone Else',
        description: 'Some company requested Adobe access for their product data',
        picUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/7/72/ARC%27TERYX_logo.svg/1280px-ARC%27TERYX_logo.svg.png',
        timestamp: new Date(Date.now() - 5 * 3600 * 3600),
        domain: 'facebook.com',
        datasourceType: 'GA360',
    }]);
}

export const fetchDatasourcesProviding = async (): Promise<DatasourceRequest[]> => {
    console.log('fetch datasources PROVIDING...')

    return Promise.resolve([{
        name: 'PROVIDING Daniel J. Kochobe',
        description: 'Arc\'Teryx requested Adobe access for their product data',
        picUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/7/72/ARC%27TERYX_logo.svg/1280px-ARC%27TERYX_logo.svg.png',
        timestamp: new Date('2020-08-24'),
        domain: 'providing-google.com',
        datasourceType: 'GA360',
    },
    {
        name: 'PROVIDING Someone Else',
        description: 'Some company requested Adobe access for their product data',
        picUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/7/72/ARC%27TERYX_logo.svg/1280px-ARC%27TERYX_logo.svg.png',
        timestamp: new Date(Date.now() - 5 * 3600 * 1000),
        domain: 'google.com',
        datasourceType: 'GA',
    }]);
}

export const fetchDatasourcesReceiving = async (): Promise<DatasourceRequest[]> => {
    return Promise.resolve([{
        name: 'RECEIVING Daniel J. Kochobe',
        description: 'Arc\'Teryx requested Adobe access for their product data',
        picUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/7/72/ARC%27TERYX_logo.svg/1280px-ARC%27TERYX_logo.svg.png',
        timestamp: new Date('2020-08-24'),
        domain: 'receiving-google.com',
        datasourceType: 'GA',
    },
    {
        name: 'RECEIVING Someone Else',
        description: 'Some company requested Adobe access for their product data',
        picUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/7/72/ARC%27TERYX_logo.svg/1280px-ARC%27TERYX_logo.svg.png',
        timestamp: new Date(Date.now() - 5 * 1000),
        domain: 'google.com',
        datasourceType: 'GA360',
    }]);
}