import { reducerWithInitialState } from "typescript-fsa-reducers";

import { emptyUser, User } from "../../models/interfaces/user";
import { createUser, deleteUser, editUser, getUsers, setSelectedUser } from "../../actions/admin";
import { ascendingStringCompare } from "../../services/helpers";

export interface State {
  users: User[];
  usersLoading: number;
  selectedUser: User;
}


const testUsers = [{
  id: 1,
  firstName: 'Haha',
  lastName: 'Blah',
  email: 'haha.blah@gmail.com',
  isAdmin: true,
}, {
  id: 2,
  firstName: 'Carly',
  lastName: 'Brnakova',
  email: 'carlybrnakova@gmail.com',
  isAdmin: true,
}, {
  id: 3,
  firstName: 'Michal',
  lastName: 'Brnal',
  email: 'miki.brnak@gmail.com',
  isAdmin: false,
}, {
  id: 5,
  firstName: 'Haha',
  lastName: 'Blah',
  email: 'haha.blah@gmail.com',
  isAdmin: false,
}, {
  id: 6,
  firstName: 'Haha',
  lastName: 'Blah',
  email: 'haha.blah@gmail.com',
  isAdmin: false,
}, {
  id: 7,
  firstName: 'Haha',
  lastName: 'Blah',
  email: 'haha.blah@gmail.com',
  isAdmin: false,
},

]


export const INITIAL_STATE: State = {
  users: [],
  usersLoading: 0,
  selectedUser: emptyUser,
};


const Admin = reducerWithInitialState(INITIAL_STATE);

// Reducers
Admin.case(
  getUsers.async.done,
  (state: State, { result }): State => ({
    ...state,
    users: result,
  })
);
Admin.case(
  createUser.async.done,
  (state: State, { result }): State => ({
    ...state,
    users: result,
  })
);
Admin.case(
  setSelectedUser,
  (state: State, payload): State => ({
    ...state,
    selectedUser: payload.user,
  })
);
Admin.case(
  editUser.async.done,
  (state: State, { result }): State => ({
    ...state,
    users: result,
  })
);
Admin.case(
  deleteUser.async.done,
  (state: State, { result }): State => ({
    ...state,
    users: result,
  })
);


// Selectors

export const selectUsers = (state: State) => state.users.sort((a, b) => ascendingStringCompare(a.email, b.email));
export const selectUsersLoading = (state: State) => state.usersLoading > 0;
export const selectSelectedUser = (state: State) => state.selectedUser;

export default Admin;
