import { reducerWithInitialState } from "typescript-fsa-reducers";
import { setRoute } from "../../actions/app";
import { Product } from "../../models/interfaces/product";
import {
  getProducts,
  setProductToggle,
  getRetailers,
  setRetailerToggle,
  setChartType,
  setCategory,
  setDimension,
  setSelectAllProducts,
  setSelectAllRetail,
  setSelectNoProducts,
  setSelectNoRetail,
  setStartDate,
  setEndDate,
  getCampaigns,
  setDrilldownReport,
} from "../../actions/drilldown";
import { Retailer } from "../../models/interfaces/retailer";
import { DropdownOption } from "../../models/interfaces/dropdown-option";
import { ChartTypes } from "../../models/enums/chart-types";
import { createUrl } from "../../services/helpers";
import { saveNewReport } from "../../services/drilldown";
import { DrilldownReport } from "../../models/interfaces/drilldown-report";

export interface State {
  products: Product[];
  productsLoading: number;
  retailers: Retailer[];
  retailersLoading: number;
  chartType: ChartTypes;
  selectedCategory: string;
  selectedDimension: string;
  startDate: Date;
  endDate: Date;
  campaigns: DropdownOption[];
  campaignsLoading: number;
  drilldownReports: DrilldownReport[];
  drilldownReportsLoading: number;
}

export const INITIAL_STATE: State = {
  products: [],
  productsLoading: 0,
  retailers: [],
  retailersLoading: 0,
  chartType: ChartTypes.FUNNEL,
  selectedCategory: "",
  selectedDimension: "",
  startDate: new Date('3/10/2020'),
  endDate: new Date('3/21/2020'),
  campaigns: [],
  campaignsLoading: 0,
  drilldownReports: [],
  drilldownReportsLoading: 0,
};

const Drilldown = reducerWithInitialState(INITIAL_STATE);

// Reducers
Drilldown.case(
  getProducts.async.started,
  (state: State): State => ({
    ...state,
    productsLoading: state.productsLoading + 1,
  })
);
Drilldown.case(
  getProducts.async.failed,
  (state: State, { error }): State => ({
    ...state,
    products: [],
    productsLoading: state.productsLoading - 1,
  })
);
Drilldown.case(
  getProducts.async.done,
  (state: State, { result }): State => ({
    ...state,
    products: result,
    productsLoading: state.productsLoading - 1,
  })
);

Drilldown.case(
  setProductToggle,
  (state: State, payload): State => {
    const newArr = state.products.map((x) =>
      x.id === payload.id ? { ...x, selected: payload.selected } : x
    );
    return { ...state, products: newArr };
  }
);

Drilldown.case(
  getRetailers.async.started,
  (state: State): State => ({
    ...state,
    productsLoading: state.retailersLoading + 1,
  })
);
Drilldown.case(
  getRetailers.async.failed,
  (state: State, { error }): State => ({
    ...state,
    products: [],
    productsLoading: state.retailersLoading - 1,
  })
);
Drilldown.case(
  getRetailers.async.done,
  (state: State, { result }): State => ({
    ...state,
    retailers: result,
    productsLoading: state.retailersLoading - 1,
  })
);

Drilldown.case(
  getCampaigns.async.started,
  (state: State): State => ({
    ...state,
    campaignsLoading: state.campaignsLoading + 1,
  })
);
Drilldown.case(
  getCampaigns.async.failed,
  (state: State, { error }): State => ({
    ...state,
    campaigns: [],
    campaignsLoading: state.campaignsLoading - 1,
  })
);
Drilldown.case(
  getCampaigns.async.done,
  (state: State, { result }): State => ({
    ...state,
    campaigns: result,
    campaignsLoading: state.campaignsLoading - 1,
  })
);

Drilldown.case(
  setRetailerToggle,
  (state: State, payload): State => {
    const newArr = state.retailers.map((x) =>
      x.id === payload.id ? { ...x, selected: payload.selected } : x
    );
    return { ...state, retailers: newArr };
  }
);

Drilldown.case(
  setChartType,
  (state: State, payload): State => {
    return { ...state, chartType: payload.chartType };
  }
);

Drilldown.case(
  setCategory,
  (state: State, payload): State => {
    return { ...state, selectedCategory: payload };
  }
);
Drilldown.case(
  setDimension,
  (state: State, payload): State => {
    return { ...state, selectedDimension: payload };
  }
);
Drilldown.case(
  setSelectAllProducts,
  (state: State, payload): State => {
    return {
      ...state,
      products: state.products.map((product) => ({
        ...product,
        selected: true,
      })),
    };
  }
);
Drilldown.case(
  setSelectNoProducts,
  (state: State, payload): State => {
    return {
      ...state,
      products: state.products.map((product) => ({
        ...product,
        selected: false,
      })),
    };
  }
);
Drilldown.case(
  setSelectAllRetail,
  (state: State, payload): State => {
    return {
      ...state,
      retailers: state.retailers.map((retailer) => ({
        ...retailer,
        selected: true,
      })),
    };
  }
);
Drilldown.case(
  setSelectNoRetail,
  (state: State, payload): State => {
    return {
      ...state,
      retailers: state.retailers.map((retailer) => ({
        ...retailer,
        selected: false,
      })),
    };
  }
);

Drilldown.case(
  setStartDate,
  (state: State, payload): State => {
    return {
      ...state,
      startDate: payload,
    };
  }
);
Drilldown.case(
  setEndDate,
  (state: State, payload): State => {
    return {
      ...state,
      endDate: payload,
    };
  }
);

// Selectors

export const selectProducts = (state: State) => state.products;
export const selectProductsLoading = (state: State) =>
  state.productsLoading > 0;
export const selectRetailers = (state: State) => state.retailers;
export const selectRetailersLoading = (state: State) =>
  state.retailersLoading > 0;
export const selectChartType = (state: State) => state.chartType;
export const selectDrilldownUrl = (state: State) =>
  createUrl({
    filters: {
      "retailer_id.ids": state.retailers
        .filter((x) => x.selected === true)
        .map((retailer) => ({ id: retailer.id })),
      "product_id.ids": state.products
        .filter((x) => x.selected === true)
        .map((product) => ({ id: product.id })),
    },
    chartType: state.chartType,
    selectedMetric: state.selectedCategory,
    secondMetric: state.selectedCategory,
    selectedDimension: state.selectedDimension,
  });

export const selectStartDate = (state: State) => state.startDate;
export const selectEndDate = (state: State) => state.endDate;
export const selectCampaigns = (state: State) => state.campaigns;
export const selectCampaignsLoading = (state: State) => state.campaignsLoading > 0;
export const selectDrilldownReports = (state: State) => state.drilldownReports;
export const selectDrilldownReportsLoading = (state: State) => state.drilldownReportsLoading;

export default Drilldown;
