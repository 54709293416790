import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { ChartTypes } from "../../../models/enums/chart-types";
import { useDispatch, useSelector } from "react-redux";
import { setChartType } from "../../../actions/drilldown";
import { selectChartType } from "../../../reducers";
import { Icons } from "../../icons/icons";
import { IconButton, SelectedIconButton } from '../icon-button';

const MainDiv = styled.div`
  border: none;
  display: flex;
`;

interface Props { }

export const ChartSelector: FunctionComponent<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const currentChart = useSelector(selectChartType);
  return (
    <MainDiv>
      {Object.values(ChartTypes).map((type) =>
        currentChart === type ? (
          <SelectedIconButton
            onClick={() => dispatch(setChartType({ chartType: type }))}
          >
            <Icons color='white' icon={type} />
          </SelectedIconButton>
        ) : (
            <IconButton onClick={() => dispatch(setChartType({ chartType: type }))}>
              <Icons color='#727cf5' icon={type} />
            </IconButton>
          )
      )}
    </MainDiv>
  );
};
