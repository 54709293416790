import { reducerWithInitialState } from "typescript-fsa-reducers";
import { setRoute } from "../../actions/app";
import { Product } from "../../models/interfaces/product";
import {
  getDatasourceRequests, getDatasourcesProviding, getDatasourcesReceiving
} from "../../actions/datasources";
import { DatasourceRequest } from "../../models/interfaces/datasource-request";
import { Retailer } from "../../models/interfaces/retailer";
import { DropdownOption } from "../../models/interfaces/dropdown-option";
import { ChartTypes } from "../../models/enums/chart-types";
import { createUrl } from "../../services/helpers";

export interface State {
  requests: DatasourceRequest[];
  requestsLoading: number;
  providing: DatasourceRequest[];
  providingLoading: number;
  receiving: DatasourceRequest[];
  receivingLoading: number;
}

export const INITIAL_STATE: State = {
  requests: [],
  requestsLoading: 0,
  providing: [],
  providingLoading: 0,
  receiving: [],
  receivingLoading: 0,
};

const Datasources = reducerWithInitialState(INITIAL_STATE);

// Reducers
Datasources.case(
  getDatasourceRequests.async.started,
  (state: State): State => ({
    ...state,
    requestsLoading: state.requestsLoading + 1,
  })
);
Datasources.case(
  getDatasourceRequests.async.failed,
  (state: State, { error }): State => ({
    ...state,
    requests: [],
    requestsLoading: state.requestsLoading - 1,
  })
);
Datasources.case(
  getDatasourceRequests.async.done,
  (state: State, { result }): State => ({
    ...state,
    requests: result,
    requestsLoading: state.requestsLoading - 1,
  })
);

Datasources.case(
  getDatasourcesProviding.async.started,
  (state: State): State => ({
    ...state,
    providingLoading: state.providingLoading + 1,
  })
);
Datasources.case(
  getDatasourcesProviding.async.failed,
  (state: State, { error }): State => ({
    ...state,
    providing: [],
    providingLoading: state.providingLoading - 1,
  })
);
Datasources.case(
  getDatasourcesProviding.async.done,
  (state: State, { result }): State => ({
    ...state,
    providing: result,
    providingLoading: state.providingLoading - 1,
  })
);

Datasources.case(
  getDatasourcesReceiving.async.started,
  (state: State): State => {
    console.log('getDatasourcesReceiving - started', state);

    return ({
      ...state,
      receivingLoading: state.receivingLoading + 1,
    })
  }
);
Datasources.case(
  getDatasourcesReceiving.async.failed,
  (state: State, { error }): State => ({
    ...state,
    receiving: [],
    receivingLoading: state.receivingLoading - 1,
  })
);
Datasources.case(
  getDatasourcesReceiving.async.done,
  (state: State, { result }): State => {
    console.log('getDatasourcesReceiving - done', result);
    return ({
      ...state,
      receiving: result,
      receivingLoading: state.receivingLoading - 1,
    })
  }
);

// Selectors

export const selectDatasourceRequests = (state: State) => state.requests;
export const selectDatasourcesProviding = (state: State) => state.providing;
export const selectDatasourcesReceiving = (state: State) => state.receiving;

export default Datasources;
