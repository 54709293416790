import React, { FunctionComponent, useEffect } from "react";
import "./app.css";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import { Route as RouteType } from "../../models/interfaces/route";

import { TopBar } from "../top-bar/top-bar";
import { SideMenu } from "../side-menu/side-menu";
import { MainContent } from "../main-content/main-content";
import { FooterBar } from "../footer-bar/footer-bar";
import { Login } from "../login/login";
import { useSelector, useDispatch } from "react-redux";
import { selectIsLoggedIn, selectIsAppLoading, selectRoutes } from "../../reducers";
import { checkLoginStatus, getBrandDashboards, appLoaded, getDrilldownReports } from "../../actions/app";
import CircularProgress from "@material-ui/core/CircularProgress";


export const App: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsAppLoading);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const routes = useSelector(selectRoutes);

  useEffect(() => {
    dispatch(appLoaded());
    dispatch(checkLoginStatus());
  }, [])
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://embed.small.chat/T47VDS5M2C04LUF6G5PA.js";
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getBrandDashboards());
      // dispatch(getDrilldownReports())
    }
  }, [isLoggedIn])
  useEffect(() => {
    getAllRoutes();
  }, [routes])
  const getAllRoutes = () => {
    const toReturn: JSX.Element[] = [];
    // toReturn.push(<Route exact key={'login'} path={'/login'} render={() => <Login />} />)

    routes.forEach(route => {
      const Component: FunctionComponent<any> = route.component;
      const componentProps = route.componentProps;
      toReturn.push(<Route exact key={route.path} path={route.path} render={() => <Component {...componentProps} />} />)

      if (route.subroutes !== undefined) {
        route.subroutes.forEach(subroute => {
          const SubComponent: FunctionComponent<any> = subroute.component
          const subComponentProps = subroute.componentProps

          toReturn.push(<Route exact key={subroute.path} path={subroute.path} render={() => <SubComponent {...subComponentProps} />} />)
        })
      }
    })
    toReturn.push(<Route exact key={'default'} render={() => <Redirect to="/dashboards/" />} />) // basic default url setup
    return toReturn;
  }

  if (isLoading) {
    return (
      <div style={{ width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(4px)', position: 'absolute', top: 0, left: 0, zIndex: 5000, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress size={80} style={{ color: 'rgb(114,124,245)' }} />
      </div>
    );
  }

  return (isLoggedIn) ? (
    <div className="app">
      <div className="sideMenuContainer">
        <SideMenu />
      </div>
      <div className="appContent">
        <div className="topBarContainer">
          <TopBar />
        </div>
        <div className="mainContentContainer">
          <HashRouter basename='/' hashType='slash'>
            <Switch>
              {getAllRoutes()}
            </Switch>
          </HashRouter>
        </div>
      </div>

    </div>
  ) : (<div className="app"><Login /></div>)
    ;
};
