import styled from "styled-components";

const Button = styled.button`
  margin-left: 0px;
  margin-right: 4px;
  margin-top: 2px;
  margin-bottom: 2px;  color: #727cf5;
  border-color: #727cf5;
  border: 1px solid;
  border-radius: 4px;
  // padding: 6px;
  height: 36px;
  padding-left: 8px;
  padding-right: 8px;
//   width: 24px;
  background-color: white;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  :hover {
    color: white;
    background-color: #727cf5;
    border-color: #727cf5;
    svg {
      fill: white;
    }
  }

  :focus {
    outline: #727cf5 auto;
  }

  :disabled {
    background-color: #F6F8FA;
    color: #929599;
    border-color: #dddddd;

    &:hover {
      outline: none;
    }
  }
`;

const SelectedButton = styled.button`
  margin-left: 0px;
  margin-right: 4px;
  margin-top: 2px;
  margin-bottom: 2px;
  color: white;
  border-color: #727cf5;
  background-color: #727cf5;
  border: 1px solid;
  border-radius: 4px;
  // padding: 6px;
  // height: 24px;
  // width: 24px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  :hover {
    color: white;
    background-color: #727cf5;
    border-color: #727cf5;
  }

  :focus {
    outline: #727cf5 auto;
  }
`;


export {
  Button,
  SelectedButton
};