import { actionCreatorFactory } from "typescript-fsa";
import { asyncFactory } from "typescript-fsa-redux-thunk";
import { createNewUser, fetchDeleteUser, fetchEditUser, } from "../services/admin";
import { Route } from "../models/interfaces/route";
import { fetchDrilldownReports } from "../services/drilldown";
import { fetchUsers } from "../services/admin";
import { emptyUser, User } from "../models/interfaces/user";

const create = actionCreatorFactory("APP");
const createAsync = asyncFactory(create);

export const setSelectedUser = create<{
    user: User;
}>("SET_SELECTED_USER");

export const getUsers = createAsync<{}, User[]>(
    "GET_USERS",
    async (params, dispatch) => {
        try {
            // return Promise.resolve([emptyUser]);
            console.log('getUsers action')
            return await fetchUsers();
        } catch (e) {
            console.log("error in GET_USERS", e);
            throw e;
        }
    }
);

export const editUser = createAsync<{ user: User }, User[]>(
    "EDIT_USERS",
    async (params, dispatch) => {
        try {
            return await fetchEditUser(params.user);
        } catch (e) {
            console.log("error in EDIT_USER", e);
            throw e;
        }
    }
);

export const createUser = createAsync<{ user: User }, User[]>(
    "CREATE_USER",
    async (params, dispatch) => {
        try {
            console.log("inside createUser action with", params.user)
            // return Promise.resolve([emptyUser]);
            return await createNewUser(params.user);
        } catch (e) {
            console.log("error in CREATE_USER", e);
            throw e;
        }
    }
);

export const deleteUser = createAsync<{ userId: number }, User[]>(
    "DELETE_USER",
    async (params, dispatch) => {
        try {
            console.log("inside createUser action with", params.userId)
            // return Promise.resolve([emptyUser]);
            return await fetchDeleteUser(params.userId);
        } catch (e) {
            console.log("error in DELETE_USER", e);
            throw e;
        }
    }
);
