import React, { FunctionComponent } from "react";
import styled from 'styled-components'
import chanalyticsLogo from '../../assets/logo.png';
import { SearchIcon } from './search-icon'
import { useHistory } from "react-router-dom";

const HeaderContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  height: calc(100% - 1px);
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  height: 100%;
  align-items: center;

`;

const ChanalyticsLogo = styled.img`
  max-height: 30px;
  padding-right: 60px;
  color: #a6a6a6;
`;

const SearchButton = styled.div`
  * {
    max-height: 16px;
    max-width: 16px;
    color: #666666;
  }
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TopBar: FunctionComponent = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push('/');
  }
  return (
    <HeaderContainer>
      <Header>
        <SearchButton>
          <SearchIcon />
        </SearchButton>
      </Header>
    </HeaderContainer>
  );
};
