import { actionCreatorFactory } from "typescript-fsa";
import { asyncFactory } from "typescript-fsa-redux-thunk";
import { fetchLogin, fetchLoginStatus, fetchBrandDashboards, fetchLogout, fetchResetPassword, fetchSetNewPassword, fetchAcceptTos } from "../services/app";
import { Route } from "../models/interfaces/route";
import { fetchDrilldownReports } from "../services/drilldown";

const create = actionCreatorFactory("APP");
const createAsync = asyncFactory(create);

export const setRoute = create<{
  route: string;
}>("SET_ROUTE");

export const appLoaded = create<void>("APP_LOADED");

export const submitLoginCreds = createAsync<{ username: string, password: string, rememberMe: boolean }, { loggedIn: boolean, user: any }>(
  "SUBMIT_LOGIN_CREDS",
  async (params, dispatch) => {
    try {
      return await fetchLogin(params.username, params.password, params.rememberMe);
    } catch (e) {
      console.log("error in SUBMIT_LOGIN_CREDS", e);
      throw e;
    }
  }
);

export const submitResetPassword = createAsync<{ username: string }, boolean>(
  "SUBMIT_RESET_PASSWORD",
  async (params, dispatch) => {
    try {
      return await fetchResetPassword(params.username);
    } catch (e) {
      console.log("error in SUBMIT_RESET_PASSWORD", e);
      throw e;
    }
  }
);

export const submitNewPassword = createAsync<{ key: string, password: string }, void>(
  "SUBMIT_NEW_PASSWORD",
  async (params, dispatch) => {
    try {
      return await fetchSetNewPassword(params.key, params.password);
    } catch (e) {
      console.log("error in SUBMIT_NEW_PASSWORD", e);
      throw e;
    }
  }
);

export const submitAcceptTos = createAsync<{}, {}>(
  "SUBMIT_ACCEPT_TOS",
  async (params, dispatch) => {
    try {
      return await fetchAcceptTos();
    } catch (e) {
      console.log("error in SUBMIT_ACCEPT_TOS", e);
      throw e;
    }
  }
);


export const logout = createAsync<{}, boolean>(
  "LOGOUT",
  async (params, dispatch) => {
    try {
      return await fetchLogout();
    } catch (e) {
      console.log("error in LOGOUT", e);
      throw e;
    }
  }
);


export const checkLoginStatus = createAsync<{}, { loggedIn: boolean, user: any }>(
  "CHECK_LOGIN_CREDS",
  async (params, dispatch) => {
    try {
      return await fetchLoginStatus();
    } catch (e) {
      console.log("error in CHECK_LOGIN_CREDS", e);
      throw e;
    }
  }
);

export const getBrandDashboards = createAsync<{}, Route[]>(
  "GET_BRAND_DASHBOARDS",
  async (params, dispatch) => {
    try {
      return await fetchBrandDashboards();
    } catch (e) {
      console.log("error in GET_BRAND_DASHBOARDS");
      throw e;
    }
    // routes: [{ ...routes[0], subroutes: result }, ...state.routes.slice(1)],
  }
)

export const getDrilldownReports = createAsync(
  "GET_DRILLDOWN_REPORTS",
  async (params, dispatch) => {
    try {
      return await fetchDrilldownReports();
    } catch (e) {
      console.log('error in GET_DRILLDOWN_REPORTS', e);
      throw e;
    }
  }
)