import { MainContent } from "../components/main-content/main-content";
import { IFrame } from "../components/main-content/iframe";
import { DrillDownPage } from "../components/drill-down-page/drill-down-page";
import { Route } from "../models/interfaces/route";
import { DataSources } from "../components/data-sources/data-sources";
import { AdminPage } from "../components/admin-page/admin-page";

export const adminRoute = {
  path: "/admin",
  title: "Admin",
  component: AdminPage,
  componentProps: {},
  subroutes: [],
};

export const routes: Route[] = [
  {
    path: "/dashboards",
    title: "Dashboards",
    component: IFrame,
    componentProps: {
      srcUrl: '',
    },
    subroutes: [],
  },
// TODO: Enable this when we're ready 
   {
     path: "/drilldown",
     title: "Drill Down Reports",
     component: DrillDownPage,
     componentProps: {
       srcUrl: window.location.hostname,
     },
   },
  // {
  //   path: "/data-sources",
  //   title: "Data Sources",
  //   component: DataSources,
  //   componentProps: {},
  //   subroutes: [{
  //     path: "/data-sources/retailers",
  //     title: "Retailers",
  //     component: DataSources,
  //     componentProps: {},
  //   },
  //   {
  //     path: "/data-sources/media-networks",
  //     title: "Media Networks",
  //     component: DataSources,
  //     componentProps: {
  //     },
  //   }]
  // },
  {
    path: '/help',
    title: "Help",
    component: DrillDownPage,
    componentProps: {},
    subroutes: [{
      path: '/faq',
      title: "FAQ",
      component: IFrame,
      componentProps: {
        srcUrl: 'https://chanalytics.io/faq-2',
      },
      externalUrl: 'https://chanalytics.io/faq-2'
    },
    {
      path: '/chat',
      title: "Chat",
      component: DrillDownPage,
      componentProps: {
        graph: 'column',
      },
    },
    {
      path: '/phone',
      title: 'Phone: 801.299.3239',
      component: IFrame,
      componentProps: {
        srcUrl: '',
      },
    },
    {
      path: '/email',
      title: 'Email: suport@chanalytics.io',
      component: IFrame,
      componentProps: {
        srcUrl: '',
      },
    },
    ]
  },
  {
    path: "/legal",
    title: "Legal",
    component: DrillDownPage,
    componentProps: {},
    subroutes: [
      //   {
      //   path: "/legal/tos",
      //   title: "Terms of Service",
      //   component: DrillDownPage,
      //   componentProps: {
      //     graph: 'funnel',
      //   },
      // },
      {
        path: "/legal/privacy-policy",
        title: "Privacy Policy",
        component: IFrame,
        componentProps: {
          srcUrl: 'https://chanalytics.io/privacy',
        },
        externalUrl: 'https://chanalytics.io/privacy',
      },
      {
        path: "/legal/ccpa-gdpr",
        title: "CCPA & GDPR",
        component: IFrame,
        externalUrl: 'https://www.dropbox.com/s/qcke1ipajwubw87/Chanalytics%20Security%20Overview%202.1%20%282%29.pdf?dl=0',
        componentProps: {
          srcUrl: 'https://www.dropbox.com/s/qcke1ipajwubw87/Chanalytics%20Security%20Overview%202.1%20%282%29.pdf?dl=0'
        },
      }
    ]
  },
  { ...adminRoute },
];


