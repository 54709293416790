import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Route } from "../../../models/interfaces/route";
import { useSelector, useDispatch } from "react-redux";
import { setRoute } from "../../../actions/app";
import { selectRoute } from "../../../reducers";

interface Props {
  route?: string;
  externalUrl?: string;
  subroutes?: Route[];
  title: string;
}

interface LayoutProps {
  isSelected?: boolean;
  isSubroute?: boolean;
}

const MenuTab = styled.div<LayoutProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
`;

const MenuTitle = styled.div<LayoutProps>`
  width: 100%;
  font-size: 11px;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  text-align: left;
  padding-top: 24px;
  display: flex;
`;

const SubMenuTitle = styled.div<LayoutProps>`
  padding: 8px 0px 0;
  font-size: 14px;
  font-weight: 400;
  color: #bfc3ce;
  text-align: left;
  display: flex;
  cursor: pointer;
`;

export const Tab: FunctionComponent<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClick = (route: string | undefined) => {
    if (route !== undefined) {
      dispatch(setRoute({ route: route }));
      history.push(route);
    }
  };
  const handleExternalLink = (extLink: string) => {
    window.open(extLink, '_blank');
  }
  const renderSubroutes = () => {
    return props.subroutes?.map((subroute) => {
      return <SubMenuTitle onClick={() => subroute.externalUrl !== undefined ? handleExternalLink(subroute.externalUrl) : handleClick(subroute.path)}>
        {/* <img src={'../../../assets/dashboard-24px.svg'} /? */}
        <span style={{ width: '8px' }} />
        <span style={{ paddingLeft: '8px' }}>{subroute.title}</span>
      </SubMenuTitle>;
    });
  };
  return (
    <>
      {/* <MenuTab onClick={() => props.externalUrl !== undefined ? handleExternalLink(props.externalUrl) : handleClick(props.route)}> */}
      <MenuTab onClick={() => handleClick(props.route)}>
        <MenuTitle>
          {props.title}
        </MenuTitle>
      </MenuTab>
      {renderSubroutes()}

    </>
  );
};
