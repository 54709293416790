import { actionCreatorFactory } from "typescript-fsa";
import { asyncFactory } from "typescript-fsa-redux-thunk";
import { Product } from "../models/interfaces/product";
import {
  fetchProducts,
  fetchSammy,
  fetchRetailers,
  fetchCampaigns,
} from "../services/drilldown";
import { Retailer } from "../models/interfaces/retailer";
import { ChartTypes } from "../models/enums/chart-types";
import { DropdownOption } from "../models/interfaces/dropdown-option";
import { DatasourceRequest } from "../models/interfaces/datasource-request";
import {
  fetchDatasourceRequests,
  fetchDatasourcesProviding,
  fetchDatasourcesReceiving,
} from '../services/datasources';

const create = actionCreatorFactory("DRILLDOWN");
const createAsync = asyncFactory(create);

export const getDatasourceRequests = createAsync<{}, DatasourceRequest[]>(
  "GET_DATASOURCE_REQUESTS",
  async (params, dispatch) => {
    try {
      return await fetchDatasourceRequests();
    } catch (e) {
      console.log("error in GET_DATASOURCE_REQUESTS", e);
      throw e;
    }
  }
);

export const getDatasourcesProviding = createAsync<{}, DatasourceRequest[]>(
  "GET_DATASOURCES_PROVIDING",
  async (params, dispatch) => {
    try {
      console.log('getDatasourcesProviding', await fetchDatasourcesProviding())
      return await fetchDatasourcesProviding();
    } catch (e) {
      console.log("error in GET_DATASOURCES_PROVIDING", e);
      throw e;
    }
  }
);

export const getDatasourcesReceiving = createAsync<{}, DatasourceRequest[]>(
  "GET_DATASOURCE_RECEIVING",
  async (params, dispatch) => {
    try {
      return await fetchDatasourcesReceiving();
    } catch (e) {
      console.log("error in GET_DATASOURCE_RECEIVING", e);
      throw e;
    }
  }
);


export const setProviding = create<void>("SET_PROVIDING");
export const setDimension = create<void>("SET_RECEIVING");

export const setStartDate = create<Date>("SET_START_DATE");
export const setEndDate = create<Date>("SET_END_DATE");