import sammy from '../../assets/sammy.json'
import { retailers } from '../../constants/retailers'
import { DrilldownReport } from '../../models/interfaces/drilldown-report';
import { DrillDownPage } from '../../components/drill-down-page/drill-down-page';
import { IFrame } from '../../components/main-content/iframe';

export const fetchProducts = async () => {
    const url = process.env.REACT_APP_WEB2PY_BASE_URL + "/xw/dash/getProducts.json?data=undefined";
    return await fetch(url).then((data) => data.json()).then((products) => {
        return products.map((prod: { [key: string]: any }) => ({
            id: prod.id,
            fullName: prod.full_name,
            selected: true,
        }))
    })
}

interface Sammy {
    headers: { [key: string]: any; name: string }[];
    rows: (any)[][];
}
const getHeaders = (headers: { [key: string]: any; name: string }[]) => {
    return headers.map(header => header.name);
}

export const fetchSammy = () => {
    // return fetch('http://127.0.0.1:8000/xw/static/sammy.json')
    //     .then(res => res.json())
    return Promise.resolve(sammy)
        .then((data: { allPV: Sammy, allSku: Sammy, allTop: Sammy }) => {
            console.log('data', data)
            const allPvHeaders = getHeaders(data.allPV.headers);
            const allPv = data.allPV.rows.map(row => {
                const newObj: { [key: string]: any } = {};
                allPvHeaders.forEach((header, i) => {
                    newObj[header] = row[i];
                })
                return newObj;
            });
            const allSkuHeaders = getHeaders(data.allSku.headers);
            const allSku = data.allSku.rows.map(row => {
                const newObj: { [key: string]: any } = {};
                allSkuHeaders.forEach((header, i) => {
                    newObj[header] = row[i];
                })
                return newObj;
            });
            const allTopHeaders = getHeaders(data.allPV.headers);
            const allTop = data.allTop.rows.map(row => {
                const newObj: { [key: string]: any } = {};
                allTopHeaders.forEach((header, i) => {
                    newObj[header] = row[i];
                })
                return newObj;
            });
            console.log('allPv', allPv)
            console.log('allSku', allSku)
            console.log('allTop', allTop)
            return {
                allPv,
                allSku,
                allTop,
            };
        })

}

export const fetchRetailers = async () => {
    const url = process.env.REACT_APP_WEB2PY_BASE_URL + "/xw/dash/api/retailers";
    return await fetch(url).then((data) => data.json()).then((retailers) => {
        console.log('retailers', retailers)
        return retailers.map((retailer: any) => ({
            id: retailer.id,
            name: retailer.name,
            selected: true,
        }))
    })
}

export const fetchCampaigns = async () => {
    const url = process.env.REACT_APP_WEB2PY_BASE_URL + "/xw/dash/api/campaigns";
    return await fetch(url).then((data) => data.json()).then((campaigns) => {
        console.log('campaign', campaigns)
        return campaigns.map((campaign: any) => ({
            value: campaign.id,
            label: campaign.name,
        }))
    })
}

export const fetchDrilldownReports = async () => {
    const url = process.env.REACT_APP_WEB2PY_BASE_URL + "/xw/dash/api/drilldown-report";

    return await fetch(url, {
        mode: 'cors',
        headers: {
            'content-type': 'application/json',
        }
    }).then((data) => data.json()).then((reports) => {
        console.log('GET reports', reports)
        // TODO: clean this up when we no longer allow saving empty report names
        return reports.map((report: any) => {
            const name = report.name || '[empty]'
            return (
                {
                    path: `/drilldown/${report.id}`,
                    title: name,
                    component: IFrame,
                    componentProps: {
                        srcUrl: report.url
                    }
                });
        });
    });
}

export const saveNewReport = async (report: DrilldownReport) => {
    const url = process.env.REACT_APP_WEB2PY_BASE_URL + "/xw/dash/api/drilldown-report";

    return await fetch(url, {
        mode: 'cors',
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            name: report.title,
            url: report.url,
            is_shared: false,
        })
    }).then((data) => data.json()).then((reports) => {
        return reports.map((report: any) => ({
            path: `/drilldown/${report.id}`,
            title: report.name || '[empty]',
            component: IFrame,
            componentProps: {
                srcUrl: report.url
            }
        }))
    })
}
