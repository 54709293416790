import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { HashRouter, Switch, Route } from "react-router-dom";
import { routes } from "../../constants/routes";
import { Route as RouteType } from "../../models/interfaces/route";
import {
  EmptyComponentProps,
  IFrameComponentProps,
} from "../../models/interfaces/route";
import { IFrame } from "../main-content/iframe";
import { MetricCard } from "../metric-card/metric-card";
import { useDispatch, useSelector } from "react-redux";
import {
  getProducts,
  getSammy,
  setProductToggle,
  getRetailers,
  setRetailerToggle,
  setCategory,
  setDimension,
  setSelectAllProducts,
  setSelectNoProducts,
  setSelectAllRetail,
  setSelectNoRetail,
  setStartDate,
  setEndDate,
  getCampaigns,
  setDrilldownReport,
} from "../../actions/drilldown";
import {
  selectProducts,
  selectRetailers,
  selectDrilldownUrl,
  selectStartDate,
  selectEndDate,
  selectCampaigns,
} from "../../reducers";
import { CheckboxItem } from "./checkbox-item/checkbox-item";
import Select, { ValueType } from "react-select";
import { ChartSelector } from "./chart-selector/chart-selector";
import TextField from "@material-ui/core/TextField";
import { SearchField } from "./search-field/search-field";
import "./drill-down-page.css";
import { categories, dimensions } from "../../constants/categories";
import { DatePicker } from "./date-picker/date-picker";
import { CircularProgress } from "@material-ui/core";
import SaveReportPopdown from './save-report-popdown/save-report-popdown';

const Button = styled.button`
  // padding: 16px;
  margin: 2px;
  color: #727cf5;
  border-color: #727cf5;
  border: 1px solid;
  border-radius: 2px;
  padding: 4px 12px;
  height: 24px;
`;

const IFrameContainer = styled.div`
  width: 100%;
  height: 100%;
  border: none;
`;

const MetricCardsContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PageContainer = styled.div`
  width: 100%;
  height: calc(100% - 62px);
  display: flex;
  flex-direction: row;
`;

const MainVertical = styled.div`
  // border: 2px solid green;
  height: 100%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
`;

const FilterVertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // border: 2px solid orange;
  width: 320px;
`;

const RetailerSelector = styled.div`
  height: 30px;
  width: 100px;
  border: 2px solid grey;
`;

const SkuSelector = styled.div`
  height: 300px;
  width: 75px;
  border: 2px solid aqua;
`;

const MetricCardWrapper = styled.div`
  padding-top: 30px;
  padding-left: 30px;
  padding-bottom: 30px;
`;

const FilterSection = styled.div`
  overflow-y: auto;
  padding: 11px 32px 16px;
  background-color: white;
  border: solid 1px #d9d9d9;
  flex-shrink: 0;
  flex-grow: 1;
`;

const FilterHorizontal = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  background-color: #e4e9ef;
  border: solid 1px #d9d9d9;
  align-items: center;
`;

const FilterHorizontalSecondary = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: solid 1px #d9d9d9;
  align-items: center;
`;

const StyledSelect = styled(Select)``;

const CheckBoxItemStyled = styled(CheckboxItem)`
  & input[type="checkbox"] {
    background-color: red;
    color: red;
  }
`;

const SelectionRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const LeftFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px;
`;

const RightFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px;
`;

const SelectorDescriptor = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #525252;
  font-family: "Overpass";
`;

/**
Campaign Page Views:	37280
Unique PDP Views:	745
Orders:	20                      -> New Orders
Units Sold:	20                  -> New Customers
Revenue:	$1,933.05           -> Growth
Conversion Rate:	2.7%        -> Conversion Rate
Average Sale Price:	$96.65
 */

/*
http://127.0.0.1:8000/xw/dash#?chartType=donut&selectedMetric=ga:uniquePageviews&secondMetric=ga:uniquePageviews&selectedDimension=category&secondDimension=ga:date&showDim2=false&showMetric2=false&campaign_id=31&selectedUnit=days&endDate=2016-03-21&startDate=2016-03-10&country_id.ids=%5B%7B%7D%5D&retailer_id.ids=%5B%7B%22id%22:106%7D,%7B%22id%22:107%7D,%7B%22id%22:103%7D,%7B%22id%22:104%7D,%7B%22id%22:105%7D%5D&product_id.ids=%5B%7B%22id%22:26693%7D,%7B%22id%22:26692%7D,%7B%22id%22:26694%7D,%7B%22id%22:26690%7D,%7B%22id%22:26697%7D,%7B%22id%22:26695%7D,%7B%22id%22:26691%7D,%7B%22id%22:26696%7D,%7B%22id%22:26698%7D,%7B%22id%22:26718%7D,%7B%22id%22:26717%7D,%7B%22id%22:26716%7D,%7B%22id%22:26702%7D,%7B%22id%22:26701%7D,%7B%22id%22:26703%7D,%7B%22id%22:26699%7D,%7B%22id%22:26706%7D,%7B%22id%22:26704%7D,%7B%22id%22:26700%7D,%7B%22id%22:26705%7D,%7B%22id%22:26707%7D,%7B%22id%22:26689%7D,%7B%22id%22:26682%7D,%7B%22id%22:26673%7D,%7B%22id%22:26672%7D,%7B%22id%22:26681%7D,%7B%22id%22:26683%7D,%7B%22id%22:26670%7D,%7B%22id%22:26679%7D,%7B%22id%22:26674%7D,%7B%22id%22:26686%7D,%7B%22id%22:26677%7D,%7B%22id%22:26684%7D,%7B%22id%22:26675%7D,%7B%22id%22:26671%7D,%7B%22id%22:26680%7D,%7B%22id%22:26676%7D,%7B%22id%22:26685%7D,%7B%22id%22:26688%7D,%7B%22id%22:26678%7D,%7B%22id%22:26687%7D,%7B%22id%22:26711%7D,%7B%22id%22:26710%7D,%7B%22id%22:26708%7D,%7B%22id%22:26712%7D,%7B%22id%22:26713%7D,%7B%22id%22:26709%7D,%7B%22id%22:26714%7D,%7B%22id%22:26664%7D,%7B%22id%22:26663%7D,%7B%22id%22:26660%7D,%7B%22id%22:26665%7D,%7B%22id%22:26715%7D,%7B%22id%22:26668%7D,%7B%22id%22:26666%7D,%7B%22id%22:26661%7D,%7B%22id%22:26667%7D,%7B%22id%22:26669%7D%5D&ga:mediumDisalbed.ids=%5B%5D
http://127.0.0.1:8000/xw/dash#?chartType=donut&selectedMetric=ga:uniquePageviews&secondMetric=ga:uniquePageviews&selectedDimension=category&secondDimension=ga:date&showDim2=false&showMetric2=false&campaign_id=31&selectedUnit=days&endDate=2016-03-21&startDate=2016-03-10&country_id.ids=%5B%7B%7D%5D&retailer_id.ids=%5B%7B%22id%22:106%7D,%7B%22id%22:107%7D,%7B%22id%22:103%7D,%7B%22id%22:104%7D,%7B%22id%22:105%7D%5D&product_id.ids=%5B%7B%22id%22:26692%7D,%7B%22id%22:26694%7D,%7B%22id%22:26690%7D,%7B%22id%22:26697%7D,%7B%22id%22:26695%7D,%7B%22id%22:26691%7D,%7B%22id%22:26696%7D,%7B%22id%22:26698%7D,%7B%22id%22:26718%7D,%7B%22id%22:26717%7D,%7B%22id%22:26716%7D,%7B%22id%22:26702%7D,%7B%22id%22:26701%7D,%7B%22id%22:26703%7D,%7B%22id%22:26699%7D,%7B%22id%22:26706%7D,%7B%22id%22:26704%7D,%7B%22id%22:26700%7D,%7B%22id%22:26705%7D,%7B%22id%22:26707%7D,%7B%22id%22:26689%7D,%7B%22id%22:26682%7D,%7B%22id%22:26673%7D,%7B%22id%22:26672%7D,%7B%22id%22:26681%7D,%7B%22id%22:26683%7D,%7B%22id%22:26670%7D,%7B%22id%22:26679%7D,%7B%22id%22:26674%7D,%7B%22id%22:26686%7D,%7B%22id%22:26677%7D,%7B%22id%22:26684%7D,%7B%22id%22:26675%7D,%7B%22id%22:26671%7D,%7B%22id%22:26680%7D,%7B%22id%22:26676%7D,%7B%22id%22:26685%7D,%7B%22id%22:26688%7D,%7B%22id%22:26678%7D,%7B%22id%22:26687%7D,%7B%22id%22:26711%7D,%7B%22id%22:26710%7D,%7B%22id%22:26708%7D,%7B%22id%22:26712%7D,%7B%22id%22:26713%7D,%7B%22id%22:26709%7D,%7B%22id%22:26714%7D,%7B%22id%22:26664%7D,%7B%22id%22:26663%7D,%7B%22id%22:26660%7D,%7B%22id%22:26665%7D,%7B%22id%22:26715%7D,%7B%22id%22:26668%7D,%7B%22id%22:26666%7D,%7B%22id%22:26661%7D,%7B%22id%22:26667%7D,%7B%22id%22:26669%7D%5D&ga:mediumDisalbed.ids=%5B%5D
*/

export const DrillDownPage: FunctionComponent<any> = (
  props: IFrameComponentProps
) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProducts());
    dispatch(getSammy());
    dispatch(getRetailers());
    dispatch(getCampaigns());
  }, []);

  const iFrameUrl = useSelector(selectDrilldownUrl);

  const products = useSelector(selectProducts);
  const retailers = useSelector(selectRetailers);
  const campaigns = useSelector(selectCampaigns);

  const [retailFilter, setRetailFilter] = useState<string>("");
  const [productFilter, setProductFilter] = useState<string>("");
  const startDate = useSelector(selectStartDate);
  const endDate = useSelector(selectEndDate);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const handleCategory = (category: { value: string; label: string }) => {
    dispatch(setCategory(category.value ?? ""));
  };
  const handleDimension = (dimension: { value: string; label: string }) => {
    dispatch(setDimension(dimension.value));
  };
  const selectStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      color: state.isSelected ? "white" : "rgba(114,124,245)",
      backgroundColor: state.isSelected ? "rgba(114,124,245)" : "white",
      width: "100%",
      fontSize: '13px',

      ":hover": {
        backgroundColor: "rgba(114,124,245,0.5)",
        color: "white",
        fontWeight: "600",
      },
    }),
    control: (provided: any, state: any) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      width: 250,
      backgroundColor: "#fff",
      border: "1px solid rgba(114,124,245)",
      borderRadius: "3px",
      margin: "15px",
      fontSize: "14px",

      ":hover": {
        backgroundColor: "rgba(114,124,245)",
        color: "white",
        border: "1px solid rgba(114,124,245)",

        "*": {
          color: "white",
        },
      },
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      width: '100%',
      'z-index': 100,
    }),
    menuPortal: (provided: any, state: any) => ({
      ...provided,
      width: '100%',
      marginTop: '2px',
      zIndex: 100,
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: "rgba(114,124,245)",

      ":hover": {
        ...provided[":hover"],
        color: "white",
      },
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: "rgba(114,124,245)",

      ":hover": {
        color: "white",
      },
    }),
    singleValue: (provided: any, state: any) => ({
      color: "rgba(114,124,245)",
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      color: "rgba(114,124,245)",
    }),
  };

  const pageLoading = false;

  const renderSpinner = () => pageLoading ? (
    <div style={{ width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(4px)', position: 'absolute', top: 0, left: 0, zIndex: 5000, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress size={80} style={{ color: 'rgb(114,124,245)' }} />
    </div>
  ) : null;

  const saveReport = (title: string) => {
    dispatch(setDrilldownReport({ title, url: iFrameUrl }));
  };

  return (
    <>
      <FilterHorizontal>
        <LeftFlexRow>
          <SelectorDescriptor>COMPARE</SelectorDescriptor>
          <StyledSelect
            options={categories}
            placeholder="Select category..."
            styles={selectStyles}
            onChange={handleCategory}
          />
          <SelectorDescriptor>BY</SelectorDescriptor>
          <StyledSelect options={dimensions} placeholder='Select dimension...' styles={selectStyles} onChange={handleDimension} />
        </LeftFlexRow>
        <RightFlexRow>
          <SelectorDescriptor>CAMPAIGN:</SelectorDescriptor>
          <StyledSelect options={campaigns} styles={selectStyles} />
        </RightFlexRow>
      </FilterHorizontal>
      <PageContainer>
        <MainVertical>
          <FilterHorizontalSecondary>
            <div style={{ marginLeft: '14px' }}>
              <ChartSelector />
            </div>
            <RightFlexRow>
              <SaveReportPopdown onSaveReportClick={saveReport} />
              <DatePicker value={startDate} onChange={(newDate) => dispatch(setStartDate(newDate))} />
              <SelectorDescriptor style={{ padding: '8px' }}> TO </SelectorDescriptor>
              <DatePicker value={endDate} onChange={(newDate) => dispatch(setEndDate(newDate))} />
            </RightFlexRow>
          </FilterHorizontalSecondary>
          <IFrameContainer>
            <IFrame srcUrl={iFrameUrl} doNotScroll />
          </IFrameContainer>
        </MainVertical>
        <FilterVertical>
          <FilterSection style={{ flexGrow: 0 }}>
            <SelectionRow>
              <SearchField
                label="Retailers"
                value={retailFilter}
                onChange={setRetailFilter}
              />
            </SelectionRow>
            <div style={{ marginBottom: "8px" }} />
            {/* <Button
                onClick={() => {
                  dispatch(setSelectAllRetail());
                }}
              >
                All
              </Button>
              <Button
                onClick={() => {
                  dispatch(setSelectNoRetail());
                }}
              >
                None
              </Button> */}
            {retailers
              .filter((x) =>
                x.name.toLowerCase().includes(retailFilter.toLowerCase())
              )
              .map((retailer, i) => (
                <CheckBoxItemStyled
                  key={i}
                  label={retailer.name}
                  isChecked={retailer.selected}
                  onCheckToggle={() =>
                    dispatch(
                      setRetailerToggle({
                        id: retailer.id,
                        selected: !retailer.selected,
                      })
                    )
                  }
                />
              ))}
          </FilterSection>
          <FilterSection style={{ flexShrink: 1 }}>
            <SelectionRow>
              <SearchField
                label="Products"
                value={productFilter}
                onChange={setProductFilter}
              />
            </SelectionRow>
            <div style={{ marginBottom: "8px" }} />
            {/* <Button
                onClick={() => {
                  dispatch(setSelectAllProducts());
                }}
              >
                All
              </Button>
                <Button 
                  onClick={() => {
                    dispatch(setSelectNoProducts());
                  }}
                >
                  None
              </Button>
            </div> */}
            {products
              .filter((x) =>
                x.fullName.toLowerCase().includes(productFilter.toLowerCase())
              )
              .map((product, i) => (
                <CheckBoxItemStyled
                  key={i}
                  label={product.fullName}
                  isChecked={product.selected}
                  onCheckToggle={() =>
                    dispatch(
                      setProductToggle({
                        id: product.id,
                        selected: !product.selected,
                      })
                    )
                  }
                />
              ))}
          </FilterSection>
        </FilterVertical>
      </PageContainer>
      {renderSpinner()}
    </>
  );
};
