import { ChartTypes } from "../../models/enums/chart-types";

export const createUrl = (params: {
  filters: { [key: string]: string | { [key: string]: number | string }[] };
  chartType: ChartTypes;
  selectedMetric: string;
  secondMetric: string;
  selectedDimension: string;
}) => {
  let url = process.env.REACT_APP_WEB2PY_BASE_URL + "/xw/dash#?secondDimension=ga:date&showDim2=false&showMetric2=false&campaign_id=31&selectedUnit=days&endDate=2016-03-21&startDate=2016-03-10&country_id.ids=[{}]&ga:mediumDisalbed.ids=[]";
  console.log(url);
  // let url =
  //   "http://" + hostname + "/xw/dash#?secondDimension=ga:date&showDim2=false&showMetric2=false&campaign_id=31&selectedUnit=days&endDate=2016-03-21&startDate=2016-03-10&country_id.ids=[{}]&ga:mediumDisalbed.ids=[]";
  for (const property in params.filters) {
    console.log(`${property}: ${JSON.stringify(params.filters[property])}`);
    url += `&${property}=${JSON.stringify(params.filters[property])}`;
  }
  url += `&chartType=${params.chartType}`;
  url += `&selectedMetric=${params.selectedMetric}`;
  url += `&secondMetric=${params.secondMetric}`;
  if (params.selectedDimension !== 'none') {
    url += `&funnelDimension=${params.selectedDimension}`;
    url += `&selectedDimension=${params.selectedDimension}`;
  }

  return encodeURI(url);

  // & retailer_id.ids=[{ "id": 106 }, { "id": 107 }, { "id": 103 }, { "id": 104 }, { "id": 105 }]
  //     & product_id.ids=[{ "id": 26693 }, { "id": 26692 }, { "id": 26694 }, { "id": 26690 }, { "id": 26697 }, { "id": 26695 }, { "id": 26691 }, { "id": 26696 }, { "id": 26698 }, { "id": 26718 }, { "id": 26717 }, { "id": 26716 }, { "id": 26702 }, { "id": 26701 }, { "id": 26703 }, { "id": 26699 }, { "id": 26706 }, { "id": 26704 }, { "id": 26700 }, { "id": 26705 }, { "id": 26707 }, { "id": 26689 }, { "id": 26682 }, { "id": 26673 }, { "id": 26672 }, { "id": 26681 }, { "id": 26683 }, { "id": 26670 }, { "id": 26679 }, { "id": 26674 }, { "id": 26686 }, { "id": 26677 }, { "id": 26684 }, { "id": 26675 }, { "id": 26671 }, { "id": 26680 }, { "id": 26676 }, { "id": 26685 }, { "id": 26688 }, { "id": 26678 }, { "id": 26687 }, { "id": 26711 }, { "id": 26710 }, { "id": 26708 }, { "id": 26712 }, { "id": 26713 }, { "id": 26709 }, { "id": 26714 }, { "id": 26664 }, { "id": 26663 }, { "id": 26660 }, { "id": 26665 }, { "id": 26715 }, { "id": 26668 }, { "id": 26666 }, { "id": 26661 }, { "id": 26667 }, { "id": 26669 }]';
};

export const getTimeAgo = (timestamp: Date) => {
  const now = new Date();
  const timeDiff = Number(now) - Number(timestamp);
  console.log(timeDiff);

  if (timeDiff < 1000 * 60) {
    return 'a moment ago';
  }
  if (timeDiff < 1000 * 60 * 60) {
    return `${Math.floor(timeDiff / 1000 / 60)} minutes ago`;
  }
  if (timeDiff < 1000 * 60 * 60 * 24) {
    return `${Math.floor(timeDiff / 1000 / 60 / 60)} hours ago`;
  }

  return `${Math.floor(timeDiff / 1000 / 60 / 60 / 24)} days ago`;
}

export const ascendingStringCompare = (a: string, b: string) => {
  if (a.toLowerCase() < b.toLowerCase()) {
    return -1;
  }
  else if (a.toLowerCase() > b.toLowerCase()) {
    return 1;
  }
  return 0
}